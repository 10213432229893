import { Link } from "gatsby";
import * as React from "react"
import { Form } from "react-bootstrap";
import requestContent from "../../../content/requestPageContent.json"
import { CustomButton } from "../../Components/CustomButton";
import { CustomInput, CustomMultiSelect, CustomTextarea } from "../../Components/CustomInput";
import { LEGAL_PAGE } from "../../constant/routes";
import { useForm } from '@formspree/react';

import SuccessCard from "../../Components/SuccessCard";
import LoaderCard from "../../Components/Loader";
import toast, { Toaster } from 'react-hot-toast';


const SupportProduct = () => {
      const [state, handleSubmit] = useForm("mknykner");

      console.log(state)
     
     if (state?.succeeded) {
         return <SuccessCard />
     }

     if (state?.errors?.length > 0) {
        toast.error(state?.errors[0]?.message)
     }


     const severityOptions = [
        { value: 'Urgently', label: 'Urgently' },
        { value: '30 minutes', label: '30 minutes' },
        { value: '1 hour', label: '1 hour' },
        { value: 'Anytime of the day', label: 'Anytime of the day' },
        
      ]

      const FormContent = () => (
               <form  onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <CustomInput label={requestContent.FormLabels[0]} type="email" name="Email" hasRequired />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <CustomInput hasRequired label={requestContent.FormLabels[1]} name="Company"/>
                </div>
            </div>
            <div className="row" style={{marginBlock: 20}}>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <CustomInput hasRequired  label={requestContent.FormLabels[2]} name="Firstname"/>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <CustomInput hasRequired type="tel" label={requestContent.FormLabels[3]} name="Lastname"/>
                </div>
            </div>
                <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <CustomInput hasRequired  label="Role" name="Role"/>
                </div>

            </div>
            <div className="row" style={{marginTop: 20}}>
                <div className="col-sm-12 col-md-6 col-lg-6">

                <CustomInput hasRequired type="text" label="Subject" name="Subject"/>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <CustomInput hasRequired label={requestContent.FormLabels[5]} name="Phone Number"/>
                </div>
            </div>
            <div className="row" style={{marginBlock: 20}}>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <CustomInput  type="file"  label="Attach File(s)" name="Attached Files" multiple="multiple"/>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <CustomMultiSelect label="When Should we contact you?" hasRequired name="When Should we contact you?" options={severityOptions} isMulti={false}/>
                </div>
            </div>
            <div className="row" style={{marginBlockStart: 20}}>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <CustomTextarea hasRequired placeholder="Write your message..." label="Detailed Description" name="Detailed Description"/>
                </div>

            </div>
            <div className="row form_text">
                <Form.Text id="terms and conditions">
                    By submitting the form, I agree to the  <Link to={LEGAL_PAGE}>Privacy Policy</Link>.
                </Form.Text>

            </div>
            <div className="required_text mt-2" style={{marginBlock: 20}}>
                <sup className="text-danger">*</sup><span>{requestContent.FormLabels[9]}</span>
            </div>
            <div className="mt-5">
                <div className="d-flex justify-content-center primaryBtn">
                    <CustomButton type="submit" text={requestContent.Buttontext} />
                </div>
            </div>
        </form>
      )
  

  return (
          <section className="request">
          <Toaster position="top-center" reverseOrder={false}/>
                <div className="request__content container">
                    <div className="row">
                        <div className="col-sm-12 col-md-7 col-lg-5 mx-auto request__content-text">
                            <h5>Submit a Support Ticket</h5>
                            {/* <p>{requestContent.HeaderText[1]}</p> */}
                        </div>
                    </div>
                    <div className="request__form">
                        <div className="row ">
                            <div className="col-sm-12 col-lg-7 col-lg-7 mx-auto">
                             {state.submitting ? (<LoaderCard />) : (<FormContent />)}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
  )
}

export default SupportProduct