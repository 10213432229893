import * as React from 'react';
import Layout from "../../../Components/Layout"
import { FooterComponent } from '../../../Components/FooterComponent';
import SupportProduct from '../../../modules/Support/SupportProduct';
import HeaderComponent from '../../../Components/HeaderComponent';
import Seo from '../../../Components/Seo';

const indexPage = () => {
  return (
    <>
      <Seo title="Submit a support ticket | Webbermill"/>
      <Layout name="request"  >
        <title>Support Product Page</title>
        <HeaderComponent hasProduct/>
        <SupportProduct  />
        <FooterComponent />
      </Layout>
    </>
  );
};

export default indexPage;